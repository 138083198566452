<template>
  <div class="">
    <notification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      ></notification>
  </div>
</template>

<script>

  import Notification from "./Notification.vue"
  import { mapState } from 'vuex'
  export default {
    components: {
      Notification
    },
    data () {
      return {

      }
    },
    methods: {

    },
    computed: mapState(['notifications'])
  }
</script>
