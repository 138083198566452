<template>
  <!--v-alert :type="notification.type" dismissible>{{ notification.message }}</v-alert-->
  <v-snackbar
   v-model="snackbar"
   top
   right
   :color="notification.type"
 >
   {{ notification.message }}
 </v-snackbar>
</template>

<script>

  export default {
    props: ['notification'],
    data () {
      return {
        timeout: null,
        snackbar: true
      }
    },
    methods: {

    },
    created() {
      this.timeout = setTimeout(() => {
        this.$store.dispatch("removeNotification",this.notification);
      },3000);
    },

    beforeDestroy() {
      clearTimeout(this.timeout);
    }


  }
</script>
