import {
  mdiCalendarOutline
} from '@mdi/js'

export default [
  {
    title: 'Sports Diary',
    icon: mdiCalendarOutline,
    children: [
      {
        title: 'Calendar',
        to: 'apps-calendar',
        resource: 'Calendar',
        action: 'read'
      },
      {
        title: 'Stats',
        to: 'apps-calendar-stats',
        resource: 'Calendar',
        action: 'read'
      },
      {
        title: 'Sports',
        to: 'apps-calendar-sports',
        resource: 'Calendar',
        action: 'edit-sports'
      }
    ],
  }
]
