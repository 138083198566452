import {
  mdiAccountOutline,
  mdiCreditCardOutline,
  mdiFileDocumentOutline
} from '@mdi/js'

export default [
  {
    title: 'CRM',
    icon: mdiFileDocumentOutline,
    children: [
      {
          title: 'Customers',
          to: 'apps-customers',
          // icon: mdiAccountOutline,
      },
      {
        title: 'Invoices',
        // icon: mdiFileDocumentOutline,
        to: 'apps-invoice-list',
      },
      {
        title: "Drafts",
        to: 'apps-invoice-drafts'
      }
    ]
  },

  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'apps-user-list',
  }
]
