import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    notifications: []
  },
  mutations: {
    PUSH_NOTIFICATION(state, notification) {
      state.notifications.push({
        ...notification,
        id: Math.random().toString(36)
      })
    },

    REMOVE_NOTIFICATION(state, notificationToRemove) {
      state.notifications = state.notifications.filter(notification => notification.id !== notificationToRemove.id)
    }
  },
  actions: {
    addNotification({commit}, notification) {
      commit("PUSH_NOTIFICATION", notification);
    },

    removeNotification({commit}, notification) {
      commit("REMOVE_NOTIFICATION", notification);
    }
  },
  modules: {
    appConfig: appConfigStoreModule,
    app,
  },
})
