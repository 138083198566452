import {
  mdiGamepadVariantOutline
} from '@mdi/js'

export default [
  {
    title: 'Games',
    icon: mdiGamepadVariantOutline,
    children: [
      {
        title: 'Memory Game',
        to: 'games-memory',
        resource: 'Games',
        action: 'read'
      },
      {
        title: 'Blackjack',
        to: 'games-blackjack',
        resource: 'Games',
        action: 'read'
      },
      {
        title: 'Hangman',
        to: 'games-hangman',
        resource: 'Games',
        action: 'read'
      }
    ],
  }
]
