import appAndPages from './app-and-pages'
import chartsAndMaps from './charts-and-maps'
import dashboard from './dashboard'
import formsAndTables from './forms-and-tables'
import others from './others'
import uiElements from './ui-elements'
import { mdiChartDonut, mdiMapOutline, mdiImageMultiple } from '@mdi/js'

// Array of sections
export default [...dashboard, ...appAndPages, ...uiElements, ...formsAndTables, ...chartsAndMaps, ...others,
  {
    title: "Klara 365",
    icon: mdiImageMultiple,
    to: "apps-lifetime-album",
    resource: 'Calendar',
    action: 'read'
  }
]
